import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useCurrentFilteredViewFromQuery from 'shared/foreground/useCurrentFilteredView';
import getSplitByDefaultValue from 'shared/foreground/utils/getSplitByDefaultValue';
import useDocumentLocations from 'shared/foreground/utils/useDocumentLocations';
import type { SplitByKey } from 'shared/types';
import { safeDecodeURIComponent } from 'shared/utils/safeDecodeURIComponent';
import urlJoin from 'shared/utils/urlJoin';

import useLocation from '../utils/useLocation';
import { FilterView } from './FilterView';

export const FilterPage = () => {
  const {
    filterQuery: encodedFilterQuery,
    openDocumentId,
    splitBy: splitByUrlParam,
    splitValue,
  } = useParams<{
    filterQuery: string;
    openDocumentId?: string;
    splitBy?: SplitByKey;
    splitValue?: string;
  }>();

  const filterQuery = safeDecodeURIComponent(encodedFilterQuery);
  const savedView = useCurrentFilteredViewFromQuery(filterQuery);

  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const documentLocations = useDocumentLocations();

  // these params are needed for not saved filtered views
  const showAllDocs = urlSearchParams.get('showAllDocs') === 'true';
  const splittingBy = urlSearchParams.get('splittingBy') ?? undefined;
  const splitBy = savedView?.splitBy ?? splitByUrlParam ?? splittingBy;

  useEffect(() => {
    if (!splitBy || splitValue || showAllDocs) {
      return;
    }

    const splitByDefaultValue = getSplitByDefaultValue(splitBy, documentLocations);

    if (splitByDefaultValue) {
      history.push(urlJoin(['/filter', encodedFilterQuery, 'split', splitBy, splitByDefaultValue]));
    }
  }, [documentLocations, history, splitBy, encodedFilterQuery, splitValue, showAllDocs]);

  const parentPath = urlJoin([
    '/filter',
    encodedFilterQuery,
    splitByUrlParam ? urlJoin(['split', splitByUrlParam, splitValue]) : '',
  ]);

  return (
    <FilterView
      documentPathPrefix={parentPath}
      key={parentPath}
      openDocumentId={openDocumentId}
      parentPath={parentPath}
      query={filterQuery}
      splitByUrlParam={splitByUrlParam}
      splittingBy={splittingBy}
      splitValue={splitValue}
      view={savedView}
    />
  );
};
